'use client';

import useTrackClientError from '~/analytics/hooks/useTrackClientError';
import Button from '~/components/atoms/Buttons/Ctas/Button/Button';
import title1Styles from '~/components/molecules/TextLockups/TextLockupTitle1.module.css';
import { dict } from '~/data/stores/Dictionary';
import { cn } from '~/utils';

import styles from './page.module.css';

export default function Error({
  error,
  reset,
}: {
  error: Error;
  reset: () => void;
}) {
  useTrackClientError(error);

  // TODO: Need to ensure design library is listing this error component
  return (
    <div className={styles.error}>
      <div className={cn(title1Styles.content, title1Styles.alignCenter)}>
        <h2 className={cn(title1Styles.titles, styles.errorTitle)}>
          {dict('clientSideErrorTitle')}
        </h2>
        <p className={cn(title1Styles.bodies, styles.errorMessage)}>
          {dict('clientSideErrorMessage')}
        </p>
        <div className={cn(title1Styles.types, styles.errorButtonsGroup)}>
          <Button
            buttonVariant="pill"
            buttonColorScheme="glassClear"
            onClick={reset}
          >
            {dict('reload')}
          </Button>
          <Button
            buttonVariant="pill"
            buttonColorScheme="glassClear"
            onClick={reset}
            to={{
              type: 'external',
              url: 'https://support.frame.io/',
            }}
          >
            {dict('support')}
          </Button>
        </div>
      </div>
    </div>
  );
}
