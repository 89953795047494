import { useEffect } from 'react';

import getBaseTrackingData from '~/analytics/getBaseTrackingData';
import trackEventSegment from '~/analytics/trackEventSegment';

/**
 * Given an error object or string will track to segment only
 *
 * @param error Error object or string
 */
const useTrackClientError = (error: Error | string) => {
  useEffect(() => {
    // Log the error to an error reporting service
    console.error(error);

    trackEventSegment('client-side-error', {
      title: String(error),
      ...getBaseTrackingData(),
    });
  }, [error]);
};

export default useTrackClientError;
